.well-heading {
    background: $brand-primary;
    margin-top: -19px;
    border-radius: 2px 2px 0px 0px;
    margin-left: -19px;
    margin-right: -19px;
    color: #fff;
}

.well-tabs {
    margin-left: -4px;
    margin-right: -4px;
    background: $brand-primary !important;
}

.no-style-btn {
    background: none;
    border: none;
    margin: 0px;
    padding: 0px;
}

.fixed-fab {
    position: fixed !important;
    bottom: 50px;
    right: 50px;
    z-index: 999;
}

.fixed-fab-left {
    position: fixed !important;
    bottom: 50px;
    left: 50px;
    z-index: 999;
}

.fixed-fab-right {
    position: fixed !important;
    bottom: 50px;
    right: 50px;
    z-index: 999;
}

.fixed-msg {
    position: fixed !important;
    bottom: 70px;
    right: 115px;
    z-index: 999;
}

.fixed-msg-left {
    position: fixed !important;
    bottom: 70px;
    left: 115px;
    z-index: 999;
}

.fixed-msg-right {
    position: fixed !important;
    bottom: 70px;
    right: 115px;
    z-index: 999;
}

.nav-tabs > li.active > a,
.nav-tabs > li.active > a:hover,
.nav-tabs > li.active > a:focus {
    background-color: rgba(255, 255, 255, 0.1) !important;
}

.list-group-separator.left:before {
    float: left;
}

ul#demo-stepper-nonlinear * {
    box-sizing: initial;
}

.mdl-step__content {
    overflow: hidden;
    overflow-x: hidden;
    overflow-y: auto;
}

.mdl-step__content .form-group {
    margin: 15px 0 0 0;
}

.mdl-stepper--horizontal {
    max-width: none !important;
}

.mdl-stepper--horizontal .mdl-step > .mdl-step__content {
    width: calc(100% - 77px) !important;
}

.mdl-step__content * {
    box-sizing: border-box !important;
}

.mdl-step .mdl-step__label-indicator {
    background: $brand-primary !important;
}

.mdl-step.mdl-step--error .mdl-step__label-indicator {
    background: #ff0000 !important;
}

.form-group .checkbox label,
.form-group .radio label,
.form-group label {
    font-size: 14px !important;
}

pre.no-styling {
    margin: 0px;
    font-size: 14px;
    border: none;
    font-family: inherit;
    background: none;
    color: inherit;
}

.input-label {
    font-size: 16px;
    font-weight: bold;
}

.check-label {
    font-size: 14px;
}

.mdl-stepper--horizontal .mdl-step__label > .mdl-step__title > * {
    max-width: none !important;
}

.highlight {
    background-image: linear-gradient(#ff0000, #ff0000), linear-gradient(#ff0000, #ff0000) !important;
}

.highlight ~ .checkbox-material .check {
    border: 2px solid #ff0000 !important;
}

.scrollable-widget {
    height: 350px;
    overflow-y: auto;
}

#sortable1,
#sortable2 {
    min-height: 500px;
    list-style-type: none;
    margin: 0 0 15px 0;
    padding: 10px;
    border: 1px solid #009688;
}

#sortable1 li,
#sortable2 li {
    padding: 5px;
    font-size: 14px;
    width: 95%;
    margin: 5px auto;
    display: block;
    cursor: move;
    cursor: -webkit-grab;
    cursor: -moz-grab;
}

.label-secondary {
    font-size: 15px;
    line-height: 2;
    background-color: rgb(0, 36, 83) !important;
}

.datepickers-container {
    z-index: 999999;
}

.list-group-item-text {
    white-space: pre-wrap;
}

.taskCount {
    color: #f44336;
    border: 2px solid #f44336;
    padding: 2px;
    border-radius: 50%;
    font-weight: 700;
    margin-left: 5px;
}

.dp::-webkit-input-placeholder {
    /* WebKit, Blink, Edge */
    font-weight: bold !important;
    opacity: 1 !important;
    color: #636b6f !important;
}

.dp:-moz-placeholder {
    /* Mozilla Firefox 4 to 18 */
    font-weight: bold !important;
    opacity: 1 !important;
    color: #636b6f !important;
}

.dp::-moz-placeholder {
    /* Mozilla Firefox 19+ */
    font-weight: bold !important;
    opacity: 1 !important;
    color: #636b6f !important;
}

.dp:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    font-weight: bold !important;
    opacity: 1 !important;
    color: #636b6f !important;
}

.dp::-ms-input-placeholder {
    /* Microsoft Edge */
    font-weight: bold !important;
    opacity: 1 !important;
    color: #636b6f !important;
}

.dp::placeholder {
    font-weight: bold !important;
    opacity: 1 !important;
    color: #636b6f !important;
}

@media (min-width: 768px) {
    .modal-dialog.stepper-modal {
        width: 80%;

        .input-label {
            font-weight: bold;
        }
    }

    .policysubmission,
    .factfind {
        .input-label {
            font-weight: bold;
        }
    }
}

@media only screen and (min-width: 841px) {
    .mdl-stepper--horizontal > .mdl-step {
        justify-content: flex-start !important;
    }
}

.report-table {
    margin-right: 2rem;
    overflow-x: auto;
    display: block;

    th {
        border-top: 1px solid #ddd !important;
        border-left: 1px solid #ddd;
        border-right: 1px solid #ddd;
    }

    tbody td {
        border-left: 1px solid #ddd;
        border-right: 1px solid #ddd;
        border-top: none !important;
    }

    &.table-hover > tbody > tr:hover {
        background-color: #1275d9;
        color: #fff;

        i,
        a {
            color: #fff;
        }

        td {
            border: none;
        }
    }

    tr:last-child {
        td {
            border-bottom: 1px solid #ddd !important;
        }
    }
}

.btn-create {
    float: right;
}

.mce-notification {
    display: none;
}

.fitwidth {
    width: 1px;
    white-space: nowrap;
}

.badge-primary {
    background: $brand-primary !important;
}

body.bulk-mode {
    .navbar-primary,
    .btn.btn-raised.btn-primary,
    .well-heading,
    .well-tabs,
    .label-primary {
        &:hover {
            background-color: lighten(red, 20%);
        }
        background-color: red !important;
        background: red !important;
    }

    .table-striped a {
        color: red;
    }

    .form-control,
    .form-group .form-control {
        border: 0;
        background-image: -webkit-gradient(linear, left top, left bottom, from(red), to(red)),
            -webkit-gradient(linear, left top, left bottom, from(#d2d2d2), to(#d2d2d2));
        background-image: -webkit-linear-gradient(red, red), -webkit-linear-gradient(#d2d2d2, #d2d2d2);
        background-image: -o-linear-gradient(red, red), -o-linear-gradient(#d2d2d2, #d2d2d2);
        background-image: linear-gradient(red, red), linear-gradient(#d2d2d2, #d2d2d2);
    }

    .panel-primary {
        border-color: red;

        > .panel-heading {
            background-color: red;
        }
    }
}

#bulk-mode-checkbox.togglebutton label input[type="checkbox"]:checked + .toggle {
    background-color: rgba(80, 80, 80, 0.7);
}

#bulk-mode-checkbox.togglebutton label input[type="checkbox"]:checked + .toggle:after {
    background-color: red;
}
